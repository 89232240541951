import { initFilter } from './components/_initFilter';
import { initAccordion } from './components/_initAccordion';
import { initSliderContentBtn } from './components/_initSliderContentBtn';
import { initScrollAnimation } from './components/_initScrollAnimation';
import { initValidFormLanding } from './components/_initValidFormLanding';

window.addEventListener('load', handleOnLoadPage, false);

function handleOnLoadPage() {
    initSliderContentBtn();
    initFilter();
    initAccordion();
    initScrollAnimation();
    initValidFormLanding();
}
