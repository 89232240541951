import Swiper from 'swiper';

export const initSliderContentBtn = () => {
    var swiper = new Swiper('[data-content-btn-swiper]', {
        loop: true,

        breakpoints: {
            0: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 15,
            },
            1024: {
                slidesPerView: 6,
                spaceBetween: 20,
            },

            1400: {
                slidesPerView: 8,
                spaceBetween: 20,
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
};
