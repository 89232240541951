export const initFilter = () => {
    const filter = document.querySelector('[data-filter]');
    const filterOverlay = document.querySelector('[data-filter-overlay]');
    const filterMenu = document.querySelector('[data-filter-menu]');
    const body = document.querySelector('body');

    filter.addEventListener('click', handleOpenFilter, false);

    function handleOpenFilter() {
        filter.classList.toggle('is-active-filter-btn');
        filterMenu.classList.toggle('is-visible-filter-menu');
        body.classList.toggle('overflow-mob');

        if (window.innerWidth <= 550) {
            filterOverlay.classList.toggle('is-active-filter-overlay');
        }
    }

    window.addEventListener('click', (e) => {
        const target = e.target;
        if (!target.closest('[data-filter]') && !target.closest('[data-filter-menu]')) {
            filter.classList.remove('is-active-filter-btn');
            filterMenu.classList.remove('is-visible-filter-menu');

            if (window.innerWidth <= 550) {
                filterOverlay.classList.remove('is-active-filter-overlay');
            }
        }
    });

    const filterItems = document.querySelectorAll('[data-filter-item]');

    filterItems.forEach(function (event) {
        event.addEventListener('click', function () {
            this.classList.toggle('is-active-filter-item');
        });
    });

    const filterItemsColor = document.querySelectorAll('[data-filter-item-color]');

    filterItemsColor.forEach(function (event) {
        event.addEventListener('click', function () {
            this.classList.toggle('is-active-filter-item-color');
        });
    });
};
