export const initValidFormLanding = () => {
    if (!document.querySelector('[data-email-form]')) return;
    const input = document.querySelector('[data-validate-field-up]');
    const error = document.querySelector('[data-input-error]');

    document.querySelector('[data-submit]').addEventListener('click', (event) => {
        if (isValidUrl(input.value)) {
            error.classList.add('hide');
            input.classList.remove('email__form-input--error');
        } else {
            error.classList.remove('hide');
            input.classList.add('email__form-input--error');
            event.preventDefault();
        }
    });

    function isValidUrl(value) {
        let isValid = false;
        if (value.length > 2) isValid = true;
        return isValid;
    }

    document.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
            if (isValidUrl(input.value)) {
                error.classList.add('hide');
                input.classList.remove('email__form-input--error');
            } else {
                error.classList.remove('hide');
                input.classList.add('email__form-input--error');
                event.preventDefault();
            }
        }
    });
};
